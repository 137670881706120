import React, { useEffect, useState } from 'react';
import {jwtDecode} from "jwt-decode";
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import menuItems from '../../helpers/menuItems';
import { MenuItem } from './MenuItem';
import { logout } from 'service/utilities';

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [filteredMenus, setFilteredMenus] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!_.isEmpty(token)) {
      try {
        const decoded = jwtDecode(token);
        if(decoded && decoded.roleId){
          const menus = menuItems.filter(mn => mn.roles.includes(decoded.roleId))
          setFilteredMenus(menus)
        } else {
          logout()
        }
      } catch (error) {
        console.error("Failed to decode token:", error)
      }
    }
  }, [])

  const handleItemClick = (route) => {
    navigate(route); 
    closeSidebar();
  };

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  

 
  return (
    <>
      <div className={`deznav ${isSidebarOpen ? 'show' : ''}`}>
        <div className='deznav-scroll'>
          <ul className='metismenu' id='menu'>
            {filteredMenus.map((data) => (
              <MenuItem {...data} key={data.title} onItemClick={() => handleItemClick(data.route)} />
            ))}
          </ul>
        </div>      
      </div>
    </>
  );
};

export default Sidebar;
