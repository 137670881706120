import axios from 'axios';
import { removeCookie } from '../helpers/utis';
import Cookies from 'js-cookie';
// import { logOutAct } from '@/redux/actions/authActions';

import config from '../config';
import { Toast } from './toast';

export const axiosInstance = axios.create({
  headers: {}
});

export const cancelTokenSource = axios.CancelToken.source();

export const setAuthToken = (token) => {
  Cookies.set('token', token, { expires: 7 });
  return localStorage.setItem('token', token);
};

export const getAuthToken = () => {
  return localStorage.getItem('token');
};

export const getCookieToken = () => {
  return Cookies.get('authToken');
};

export const setLocalStorageData = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageData = (key) => {
  if (!key) return null;
  return JSON.parse(localStorage.getItem(key));
};

export const removeAuthToken = () => {
  if (typeof window !== 'undefined') localStorage.removeItem('token');
  Cookies.remove('token');
  Cookies.remove('authToken');
  return true;
};

export const logout = () => {
  axiosInstance.defaults.headers.common.Authorization = '';
  localStorage.clear();
  removeCookie('token', { secure: false });
  setTimeout(() => {
    window.location.pathname = '/auth/login';
  }, 200);

  window.history.replaceState(null, '', '/auth/login');
};




export const getServiceUrl = (baseURL) => {
  let finalURL = '';

  switch (baseURL) {
    case 'user':
      finalURL = config.api.userService;
      break;
    case 'normal':
      finalURL = config.api.normalService;
      break;
    case 'payment':
      finalURL = config.api.paymentService;
      break;
    case 'cms':
      finalURL = config.api.cmsService;
      break;
    case 'admin':
      finalURL = config.api.adminService;
      break;
    case 'internal':
      finalURL = config.api.internalUrl;
      break;
    default:
      finalURL = config.api.normalService;
  }

  return finalURL;
};

export const statusHelper = (status, data) => {
  // if (data.status === 401 && cancelTokenSource) cancelTokenSource.cancel();

  if (data.status === 401 || data.status === 403) {
    Toast({ type: 'error', message: data.statusText });
    // setTimeout(() => logOutAct(), 1000);
  }
  if (status) {
    return {
      status: data.status,
      ...data.data
    };
  }
  return data.data;
};

export const scrollHelper = ({ isTop, isSmooth = true }) => {
  if (window) {
    const behavior = isSmooth ? 'smooth' : 'auto';

    if (isTop) {
      return window.scrollTo({ top: 0, behavior });
    }
  }

  return null;
};

export const isAuthorized = async () => {
  if (
    localStorage.getItem('token') !== null &&
    localStorage.getItem('token') !== undefined
  ) {
    return true;
  }
  return false;
};

export const getImageMeta = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });
