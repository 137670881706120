import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { logout } from 'service/utilities';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import { Toast } from 'service/toast';

import _ from 'lodash';

export function MainLayout(props) {
  const { children } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const websiteGuard = useCallback(() => {
    if (_.isEmpty(localStorage.getItem('token'))) {
      sessionStorage.clear();
      logout();
      Toast({ type: 'error', message: 'Unauthorized user' });
      window.location.href = '/auth/login';
      window.location.reload(); 
    }
  }, [logout]);

  useEffect(() => {
    websiteGuard();
  });

  useEffect(() => {
    const handleWindowResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 991) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div id='main-wrapper' className={isMenuOpen ? 'show menu-toggle' : 'show'}>
      <Header onToggleMenu={toggleMenu} />
      <Sidebar />
      <div className='content-body'>{children}</div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
};
