import { useRouteError } from 'react-router-dom';

export function ErrorBoundaryFC() {
  const error = useRouteError();
  // Uncaught ReferenceError: path is not defined
  return (
    <div>
      <div className="d-flex flex-column align-items-center justify-content-center py-4">
        <div>
          <h1 className="font-Barlow text-center fw-600">
            Something went wrong :(
          </h1>
        </div>

        {error && error.toString()}
        <details style={{ whiteSpace: 'pre-wrap' }}>
          <br />
          {error.stack}
        </details>
      </div>
    </div>
  );
}
