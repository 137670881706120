import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import CodeSplitter from '../helpers/CodeSplitter';

import { ErrorBoundaryFC } from '../helpers/ErrorBoundaryFC';
import Routers from './routes';
import * as Layout from '../layout';

const routeDatas = Routers?.map(
  ({
    component,
    name,
    path: componentPath,
    childrens = [],
    redirect = '',
    componentLinkPath
  }) => {
    if (redirect)
      return {
        path: componentPath,
        element: <Navigate to={redirect} replace />
      };

    const LayoutComponent = Layout[component];
    if (childrens?.length > 0) {
      const childrenDatas = childrens?.map(
        ({
          componentPath: childComponentPath,
          name: childrenName = '',
          path: childrenPath
        }) => {
          CodeSplitter.addComponent(childComponentPath, childrenName);

          const PageComponent = CodeSplitter.getComponent(childrenName);

          return {
            path: childrenPath,
            element: (
              <LayoutComponent>
                <PageComponent />
              </LayoutComponent>
            ),
            errorElement: <ErrorBoundaryFC />
          };
        }
      );

      return {
        path: componentPath,
        // element: <LayoutComponent />,
        children: childrenDatas
      };
    }

    CodeSplitter.addComponent(componentLinkPath, name);

    const PageComponent = CodeSplitter.getComponent(name);

    return {
      path: componentPath,
      element: (
        <LayoutComponent>
          <PageComponent />
        </LayoutComponent>
      ),
      errorElement: <ErrorBoundaryFC />
    };
  }
);

export const router = createBrowserRouter(routeDatas);
