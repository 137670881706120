import React, { useState,useEffect } from 'react';
import {useNavigate} from 'react-router-dom'
import { ternaryCondition } from '../../../helpers/utis';
import { Avatar } from '../Avatar';
// import { logout } from 'service/utilities';

const ProfileDropdown = () => {
  const navigate = useNavigate()
  const [showDropdown, setShowDropdown] = useState(false);
  const userName = localStorage?.getItem('userName')
  const id = localStorage?.getItem('id')
  const handleTitleClick = () => {
    setShowDropdown(!showDropdown);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownElement = document.getElementById('profileDropdown');

      if (dropdownElement && !dropdownElement.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const getFirstName = () => {
    return userName ? userName.charAt(0).toUpperCase() + userName.slice(1) : ''
  }
  const getLastName = () => {
    const lastName = userName?.split(' ')
    return lastName ? ternaryCondition(lastName?.length > 1, lastName[lastName.length - 1], '') : ''
  }
  const logout = () => {
    Object.keys(localStorage).forEach(key => {
      localStorage.removeItem(key); 
    });
    navigate('/auth/login');
  };
  
  return (
    <ul className='navbar-nav header-right'>
      <li
       id='profileDropdown'
        className={`nav-item dropdown header-profile ${
          showDropdown ? 'show' : ''
        }`}
      >
        <a
          className='nav-link'
          href='#'
          role='button'
          data-bs-toggle='dropdown'
          onClick={handleTitleClick}
        >
          {/* <img src='images/profile/pic1.jpg' width='20' alt='' /> */}
          <div className='header-info'>
          <Avatar firstName={getFirstName()} lastName={getLastName()}  />
            <span className='header_user_info'>
             {userName}
            </span>
          </div>
        </a>
        <div
          className={`dropdown-menu dropdown-menu-right ${
            showDropdown ? 'show' : ''
          }`}
        >
         
          <div  className='dropdown-item ai-icon' onClick={logout}>
            <svg
              id='icon-logout'
              xmlns='http://www.w3.org/2000/svg'
              className='text-danger'
              width='18'
              height='18'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
              <polyline points='16 17 21 12 16 7'></polyline>
              <line x1='21' y1='12' x2='9' y2='12'></line>
            </svg>
            <span className='ml-2'>Logout </span>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default ProfileDropdown;
