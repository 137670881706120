const routers = [
    {
      path: '/',
      redirect: '/auth/login',
      auth: false
    },
    {
      component: 'AuthLayout',
      path: '/auth',
      auth: false,
      name: 'Auth',
      exact: false,
      childrens: [
        {
          component: 'Login',
          path: 'login',
          componentPath: 'pages/authentication/login/index.jsx',
          name: 'Login',
          auth: false,
          exact: false
        },
       
      ]
    },
  
    {
      component: 'MainLayout',
      path: 'solar',
      auth: false,
      name: 'user',
      exact: false,
      childrens: [
        {
          component: 'UserManagementListingPage',
          path: 'user/management',
          componentPath: 'pages/userManagement/index',
          name: 'UserManagementListingPage',
          auth: false,
          exact: false
        },
        {
          component: 'CreateUser',
          path: 'create/user',
          componentPath: 'pages/userManagement/create/index',
          name: 'CreateUserPage',
          auth: false,
          exact: false
        },
        {
          component: 'CreateUser',
          path: 'edit/user/:id',
          componentPath: 'pages/userManagement/create/index',
          name: 'CreateUserPage',
          auth: false,
          exact: false
        },
        {
          component: 'CustomerListingPage',
          path: 'customer/list',
          componentPath: 'pages/customer/index',
          name: 'CustomerListingPage',
          auth: false,
          exact: false
        },
        {
          component: 'CustomerDetails',
          path: 'customer/details/:id',
          componentPath: 'pages/customer/view/index',
          name: 'CustomerDetails',
          auth: false,
          exact: false
        },
        {
          component: 'GroupListingPage',
          path: 'group/list',
          componentPath: 'pages/group/index',
          name: 'GroupListingPage',
          auth: false,
          exact: false
        },
        {
          component: 'SingleGroupListingPage',
          path: 'single/group/:id',
          componentPath: 'pages/group/view/index',
          name: 'SingleGroupListingPage',
          auth: false,
          exact: false
        },
        // products
        {
          component: 'ProductListingPage',
          path: 'product/list',
          componentPath: 'pages/product/index',
          name: 'ProductListingPage',
          auth: false,
          exact: false
        },
        {
          component: 'CreateProduct',
          path: 'create/product',
          componentPath: 'pages/product/create/index',
          name: 'CreateProductPage',
          auth: false,
          exact: false
        },
        {
          component: 'CreateProduct',
          path: 'edit/product/:id',
          componentPath: 'pages/product/create/index',
          name: 'CreateProductPage',
          auth: true,
          exact: false
        },
        {
          component: 'CategoryList',
          path: 'category/list',
          componentPath: 'pages/category/index',
          name: 'CategoryList',
          auth: true,
          exact: false
        },
      ]
    },
  ];
  
  export default routers;
  