import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function MenuItem({
  icon,
  title,
  subOptions,
  link,
  isMobile,
  handleClose,
  idKey,
}) {
  const location = useLocation();

  const navigate = useNavigate();

  const pathName = location.pathname;

  const isActiveItem = useMemo(() => {
    return pathName.includes(idKey);
  }, [pathName, idKey]);

  const isSubActiveItem = useCallback(
    (subLink) => {
      return pathName === subLink;
    },
    [pathName]
  );

  const containsSubOptions = useMemo(
    () => subOptions?.length > 0,
    [subOptions]
  );

  const [isOpen, setOpen] = useState(false);

  const handleClick = (routeLink, type) => {
    if (containsSubOptions && type === 'main') return setOpen(!isOpen);

    // console.log(isMobile, routeLink);

    if (isMobile) handleClose();

    return navigate(routeLink);
  };

  return (
    <li className={`${isActiveItem ? 'mm-active' : ''}`}>
      <button onClick={() => handleClick(link, 'main')}>
      {icon}
      <span className='nav-text'>{title}</span>
      </button>
      <div
        className={`sub-options-container d-flex flex-column ${
          isOpen ? 'show-options' : 'hide-options'
        }`}
      >
        {subOptions?.map(({ label, subLink }) => (
          <button
            className={`sub-option-item p-0 ${
              isSubActiveItem(subLink) ? 'sub-item-active' : ''
            }`}
            type='button'
            key={subLink}
            onClick={() => handleClick(subLink, 'sub')}
          >
            {icon}
            <span className='nav-text'>{label}</span>
          </button>
        ))}
      </div>
    </li>
  );
}

MenuItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  idKey: PropTypes.string.isRequired,
  link: PropTypes.string,
  isMobile: PropTypes.bool,
  handleClose: PropTypes.func,
  subOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
};

MenuItem.defaultProps = {
  icon: '',
  title: '',
  link: '',
  subOptions: [],
  isMobile: false,
  handleClose: () => null,
};
