import { MdDashboardCustomize } from 'react-icons/md';
import { FaUserCog } from 'react-icons/fa';
import { MdProductionQuantityLimits } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { FaBuildingUser } from "react-icons/fa6";
const rootPath = '/solar';

const menuItems = [
  {
    title: 'Customer',
    icon: <FaBuildingUser />,
    link: `${rootPath}/customer/list`,
    idKey: 'customer',
    roles: [1]
  },
  {
    title: 'Group',
    icon: <FaUsers />,
    link: `${rootPath}/group/list`,
    idKey: 'group',
    roles: [1]
  },
  // {
  //   title: 'Product',
  //   icon: <MdProductionQuantityLimits />,
  //   link: `${rootPath}/product/list`,
  //   idKey: 'product',
  //   roles: [1]
  // },
  {
    title: 'Category',
    icon: <MdProductionQuantityLimits />,
    link: `${rootPath}/category/list`,
    idKey: 'category',
    roles: [2]
  },
  {
    title: 'User management',
    icon: <FaUserCog />,
    link: `${rootPath}/user/management`,
    idKey: 'user',
    roles: [1]
  },
];

export default menuItems;
