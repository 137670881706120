import React, { useState, useEffect } from 'react';
import ProfileDropdown from '../common/dropdown';
import { Avatar } from '../common/Avatar';

const Header = ({ onToggleMenu, title }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
    onToggleMenu();
  };

  useEffect(() => {
    const isMobileOrTablet = () =>
      window.matchMedia('(max-width: 768px)').matches;

    const handleResize = () => {
      setIsActive(isMobileOrTablet());
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className='nav-header'>
        <a href='#' class='brand-logo'>
          <img class='logo-abbr' src={require('../../assets/images/new_logo.png')} alt='' />
          <img class='logo-compact' src={require('../../assets/images/new_logo.png')} alt='' />
          <img class='brand-title' src={require('../../assets/images/new_logo.png')} alt='' />
        </a>

        <div class='nav-control'>
          <div
            className={`hamburger ${isActive ? 'is-active' : ''}`}
            onClick={handleClick}
          >
            <span class='line'></span>
            <span class='line'></span>
            <span class='line'></span>
          </div>
        </div>
      </div>
      <div className='header'>
        <div className='header-content'>
          <nav className='navbar navbar-expand'>
            <div className='collapse navbar-collapse justify-content-between'>
    
              <div class='header-left'>
                <h3>{title}</h3>
              </div>
             
              <ProfileDropdown />
            </div>
          </nav>
        </div>
      </div>
      {/* Backdrop */}
      {isActive && <div className="backdrop" onClick={handleClick}></div>}
    </>
  );
};

export default Header;
