import { useEffect, useRef } from 'react';

export function AuthLayout(props) {
  const { children } = props;
  const historyStack = useRef([]);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault(); 
      historyStack.current.pop();
      if (historyStack.current.length > 0) {
        const previousUrl = historyStack.current.pop();
        navigate(previousUrl);
      }
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []); 

  const navigate = (url) => {
    historyStack.current.push(window.location.href);
    window.history.pushState(null, null, url);
  };

  return (
    <div className="auth_layout">
      {children}
    </div>
  );
}
